import { useHistory, useParams } from "react-router-dom/cjs/react-router-dom.min"
import { useEffect, useState } from "react"
import { addProjectPhotos, deleteProjectPhotos, getProject, putProject } from "../../lib/requests"
import AddButton from "../Layout/AddButton";
import Loading from "../Loading";
import "../../css/Selected/selected-project-edit.css"
import "../../css/Edit/projects.css"
import { dataURLtoFile } from "../../lib/a-lib";

export default function EditSelectedProject({ pageInfo }) {
    const [title, setTitle] = useState("");
    const [titleAM, setTitleAM] = useState("");

    const [projectImages, setProjectImages] = useState([]);
    const [deletedImages, setDeletedImages] = useState([]);

    const [totalFloorArea, setTotalFloorArea] = useState("");
    const [totalFloorAreaAM, setTotalFloorAreaAM] = useState("");

    const [locationAM, setLocationAM] = useState("");
    const [location, setLocation] = useState("");

    const [designAndBuilt, setDesignAndBuilt] = useState("");
    const [designAndBuiltAM, setDesignAndBuiltAM] = useState("");

    const [description, setDescription] = useState("");
    const [descriptionAM, setDescriptionAM] = useState("");

    const [designTeam, setDesignTeam] = useState("");
    const [designTeamAM, setDesignTeamAM] = useState("");

    const [showLoading, setShowLoading] = useState(false);
    const history = useHistory();
    const { id } = useParams();


    useEffect(() => {
        const token = document.cookie.split("=");
        if (!token[1]) {
            history.push("/log-in");
        }
        setShowLoading(true)
        getProject((reqData) => {
            if (Object.keys(reqData).length) {
                setProjectImages(reqData.photos);
                setTitle(reqData.title)
                setTitleAM(reqData.title_hy)
                setLocation(reqData.location)
                setLocationAM(reqData.location_hy)
                setTotalFloorArea(reqData["total-floor-area"])
                setTotalFloorAreaAM(reqData["total-floor-area_hy"])
                setDesignAndBuilt(reqData["design-and-built"])
                setDesignAndBuiltAM(reqData["design-and-built_hy"])
                setDesignTeam(reqData["design-team"])
                setDesignTeamAM(reqData["design-team_hy"])
                setDescription(reqData.description)
                setDescriptionAM(reqData.description_hy)
            }
        }, id)
        setShowLoading(false)
    }, [id])

    function addImage(e) {
        setShowLoading(true)
        let reader = new FileReader();
        reader.readAsDataURL(e.target.files[0]);
        reader.addEventListener("load", (e) => {
            setProjectImages([...projectImages, e.target.result]);
            try {
                const image = dataURLtoFile(e.target.result);
                (async () => await addProjectPhotos(image, id))();

            } catch (err) { console.log(err); }
        });
        setShowLoading(false)
    }

    async function sendUpdatedData() {
        setShowLoading(true)
        const body = {
            "title": title,
            "title_hy": titleAM,
            "location": location,
            "location_hy": locationAM,
            "total-floor-area": totalFloorArea,
            "total-floor-area_hy": totalFloorAreaAM,
            "design-and-built": designAndBuilt,
            "design-and-built_hy": designAndBuiltAM,
            "description": description,
            "description_hy": descriptionAM,
            "design-team": designTeam,
            "design-team_hy": designTeamAM
        }
        putProject((data) => {
            history.goBack();
            setShowLoading(false)
        }, body, id)
        if (deletedImages.length) {
            try{
                await Promise.all(deletedImages.map(id => deleteProjectPhotos(id)))
            } catch (error) {
                console.log(error);
            }
        }
    }

    async function deletePhoto(photoId) {
        const res = projectImages.filter(el => el.id !== photoId);
        setProjectImages(res);
        setDeletedImages([
            ...deletedImages,
            photoId
        ]);
    }

    return (
        <div className="layout-content new-project-layout-content" style={{ height: "max-content", overflow: "hidden" }}>
            <div className="layout-content-header new-layout-content-header">
                <div className="layout-content-info">
                    <img src="../../images/back.png" alt="Back" className="back-icon" onClick={() => history.goBack()} />
                    <p className="layout-content-info-header">
                        {`${pageInfo.pageHeader}-${pageInfo.pageName}`}
                    </p>
                </div>
            </div>
            <div className="projects-layout-info main-div-for-edit-data">
                <div className="div-for-added_btn-and-photos-box">
                    <AddButton text={"Add"} change={addImage} />
                    <div className="div-for-photos-box">
                        {projectImages?.map((el, i) => <div className="projects-image-div-box projects-image-div-box-icons" key={`selected_project_photo_from_db_${i}`}>
                                <img className="selected-projects-img" src={el instanceof Object ? `https://api.anarch.am/${el.path}` : el} alt="" />
                                <div className="projects-icons-div-box">
                                    <img className="trash-icon trash-icon-edit" src="../../images/trash.png" alt="Delete" onClick={()=>deletePhoto(projectImages[i].id)} />
                                </div>
                            </div>)
                        }
                    </div>
                </div>
                <div>
                    <div className="new-project-inputs edit-project-inputs-box">
                        <input
                            type="text"
                            placeholder="*title"
                            value={title}
                            onChange={(e) => setTitle(e.target.value)}
                        />
                        <input
                            type="text"
                            placeholder="*վերնագիր"
                            value={titleAM}
                            onChange={(e) => setTitleAM(e.target.value)}
                        />
                        <input
                            type="text"
                            placeholder="*location"
                            value={location}
                            onChange={(e) => setLocation(e.target.value)}
                        />
                        <input
                            type="text"
                            placeholder="*վայր"
                            value={locationAM}
                            onChange={(e) => setLocationAM(e.target.value)}
                        />
                        <input
                            type="text"
                            placeholder="*total floor area"
                            value={totalFloorArea}
                            onChange={(e) => setTotalFloorArea(e.target.value)}
                        />
                        <input
                            type="text"
                            placeholder="*բնակելի մակերես"
                            value={totalFloorAreaAM}
                            onChange={(e) => setTotalFloorAreaAM(e.target.value)}
                        />
                        <input
                            type="text"
                            placeholder="*design and built"
                            value={designAndBuilt}
                            onChange={(e) => setDesignAndBuilt(e.target.value)}
                        />
                        <input
                            type="text"
                            placeholder="*նախագծում և իրականացում"
                            value={designAndBuiltAM}
                            onChange={(e) => setDesignAndBuiltAM(e.target.value)}
                        />
                        <input
                            type="text"
                            placeholder="*design team"
                            value={designTeam}
                            onChange={(e) => setDesignTeam(e.target.value)}
                        />
                        <input
                            type="text"
                            placeholder="*թիմ"
                            value={designTeamAM}
                            onChange={(e) => setDesignTeamAM(e.target.value)}
                        />
                        <input
                            type="text"
                            placeholder="*description"
                            className="description-input"
                            value={description}
                            onChange={(e) => setDescription(e.target.value)}
                        />
                        <input
                            type="text"
                            placeholder="*նկարագրություն"
                            className="description-input"
                            value={descriptionAM}
                            onChange={(e) => setDescriptionAM(e.target.value)}
                        />
                        <button className="add-project edit-button-selected-projects" onClick={sendUpdatedData}>
                            Edit
                        </button>
                    </div>
                </div>
            </div>

            {showLoading && <Loading />}
        </div >
    )
}