import MainLayout from "../../components/Layout/MainLayout";
import EditSelectedProject from "../../components/SelectedProjects/EditSelectedProject";

export default function EditApartamentsInProgress() {
    const pageInfo = {
        pageHeader: "Apartments",
        pageName: "Edit In Progress",
        className: "new-project-layout-left-bar",
        type: 1,
        category: 1,
    }
    return <>
        <div className="new-selected-project">
            <MainLayout showMenu={false} className="new-project-content" pageInfo={pageInfo}>
                <EditSelectedProject pageInfo={pageInfo} />
            </MainLayout>
        </div>
    </>
}