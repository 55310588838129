import React, { useState } from "react";
import { Portal } from "react-portal";
import { generalUrl } from "../../lib/constants";
import Modal from "../Modal/Modal";
export default function SelectedProjectItem({ data, delItem, editData = {included: false}, history }) {
  const [openModal, setOpenModal] = useState(false);
  return (
    <div className="selected-projects-items-other">
      <img
        className="selected-project-item-img "
        src={data.photos[0] ? `${generalUrl}/${data.photos[0].path}` : ""}
        alt=""
      />
      <div className="selected-project-item-icons-box">
        <img
          src="../images/trash.png"
          className="selected-projects-icon"
          alt=""
          onClick={() => setOpenModal(true)}
        />
        {editData.included && <img
          src="../images/edit.png"
          className="selected-projects-icon"
          alt=""
          onClick={() => history.push(`${editData.path}/${data.id}`)}
        />}
      </div>

      {openModal && (
        <Portal node={document.body}>
          <Modal
            delItem={delItem}
            currentId={data.id}
            setOpenModal={setOpenModal}
            type={"project"}
          />
        </Portal>
      )}
    </div>
  );
}
